import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  pipe
} from 'fp-ts/lib/pipeable';
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import { without, append, includes, keys, map, values, reject, assocPath } from 'ramda';
import {
  Typography, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, Badge,
  TextField, LinearProgress, useMediaQuery, Paper, Divider
} from '@material-ui/core';
import {
  green
} from '@material-ui/core/colors';
import {API, graphqlOperation} from 'aws-amplify';
import ReactPhoneInput from 'react-phone-input-mui';
import {
  ArrowForward, Check, ArrowBack
} from '@material-ui/icons';
import {
  update,
  initialState as ContactState,
  submitForm
} from '../../state/contact/ducks';
import theme from '../../themes/lightTheme';
import darkTheme from '../../themes/baseTheme';

import * as mutations from '../../graphql/mutations';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    maxWidth: '100vw',
  },
  form: {
    maxWidth: 900,
  },
  progress: {
    padding: theme.spacing()
  },
  paperRoot: {
    border: `2px solid ${theme.palette.text.primary}`,
    background: theme.palette.background.default
  },
  btnGroup: {
    display: 'flex',
    // gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    padding: theme.spacing(),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  badgeCheck: {
    fontSize: 10
  },
  badge: {
    margin: theme.spacing()
  },
  unselected: {
    border: `2px solid ${theme.palette.text.disabled}`,
  },
  selected: {
    border: `2px solid ${theme.palette.text.primary}`,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing()
  },
  success: {
    backgroundColor: green[500],
    padding: theme.spacing(),
  },
  successContent: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
  phoneInput: {
    width: '100%',
    flexGrow: 1
  },
  questionReview: {
    marginBottom: theme.spacing(),
    minWidth: 500,
    [theme.breakpoints.down('md')]: {
      minWidth: 'calc(100vw - 16px)'
    }
  },
  submitAnotherFormBtn: {
    textTransform: 'none',
    color: 'white',
    marginTop: theme.spacing(2),
    fontStyle: 'italic'
  },
  banner: {
    background: theme.palette.background.default,
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    alignItems: 'center'
  },
  companyInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '80%',
    justifyContent: 'space-between',
  },
  companyInfoColumn: {
    padding: theme.spacing(2),
    flexGrow: 1
  },
  divider: {
    margin: theme.spacing(2), minWidth: '80%', height: 2, backgroundColor: theme.palette.text.primary
  }
}));

const Question = ({
  questions,
  currentQuestion,
  review,
  updateButton,
  updateTextField,
  updatePhoneField,
  answer,
}) => {
  const classes = useStyles();
  return (
      <div className={classes.form}>
        {questions[currentQuestion]?.multipleSelect && <Typography align='center' variant='caption'>Select all that apply</Typography>}
        <div className={classes.btnGroup}>
          {questions[currentQuestion]?.options?.map(option =>
            <Badge
              className={classes.badge}
              key={option.value}
              color='primary'
              badgeContent={<Check className={classes.badgeCheck} />}
              invisible={!(questions[currentQuestion]?.multipleSelect && includes(option, answer)) && questions[currentQuestion]?.answer?.label !== option.label}
            >
              <Button
                fullWidth
                onClick={(e) => updateButton(option, currentQuestion)}
                className={(questions[currentQuestion]?.answer?.label === option.label || (questions[currentQuestion]?.multipleSelect && includes(option, answer))) ? classes.selected : classes.unselected}
                variant='outlined'>
                {option.label}
              </Button>
            </Badge>)}
          {!questions[currentQuestion]?.options && currentQuestion !== 'phone' &&
            <TextField
              fullWidth
              required={true}
              multiline={Boolean(questions[currentQuestion]?.multiline)}
              name={questions[currentQuestion]?.inputPropType}
              type={questions[currentQuestion]?.inputPropType}
              placeholder={questions[currentQuestion]?.label}
              value={questions[currentQuestion]?.answer?.value || ''}
              onChange={e => updateTextField(e, currentQuestion)} />}
          {/*{!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
            <ReactPhoneInput
              defaultCountry='us'
              disableCountryCode
              placeholder={questions[currentQuestion]?.label}
              value={questions[currentQuestion]?.answer?.value || ''}
              onChange={e => updatePhoneField(e, currentQuestion)}
              containerClass={classes.phoneInput}
              inputExtraProps={{
                name: 'phone',
                required: true,
                fullWidth: true
              }}
              component={TextField} />}*/}
            {!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
              <TextField
                fullWidth
                autoFocus={false}
                multiline={Boolean(questions[currentQuestion]?.multiline)}
                name={questions[currentQuestion]?.inputPropType}
                type={questions[currentQuestion]?.inputPropType}
                placeholder={questions[currentQuestion]?.label}
                value={questions[currentQuestion]?.answer?.value || ''}
                onChange={e => updateTextField(e, currentQuestion)} />}
        </div>
        {questions[currentQuestion]?.answer?.label === 'Other' &&
          <TextField
            value={questions[currentQuestion]?.answer?.value || ''}
            fullWidth
            required={questions[currentQuestion]?.required}
            autoFocus
            placeholder='Other...'
            onChange={e => updateTextField(e, currentQuestion)} />}
      </div>

  )
}

const FormComponent = ({
  companyInfo,
  contact,
  update,
  submitForm
}: {
  contact: typeof ContactState;
  update: any;
}) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({data: null});
  const query = `
    query ListProjectFormQuery {
      listProjectForms {
       items {
         email
         firstName
         id
       }
     }
    }
  `;

  const reviewForm = () => {
    update(['form', 'review'], true);
  }
  const {
    form: {
      currentQuestion,
      questions,
      review,
      submitSuccess
    }
  } = contact;
  // if (!currentQuestion) return null;
  const close = () => update(['form', 'currentQuestion'], null);
  const updateTextField = (e, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], e.target.value);
  };
  const updatePhoneField = (value, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], value);
  };
  const answer = questions[currentQuestion]?.answer;
  const updateButton = (value, currentQuestion) => {
    if (questions[currentQuestion]?.multipleSelect) {
      return update(
        ['form', 'questions', currentQuestion, 'answer'],
        includes(value, answer) ? answer.filter(i => i !== value) : append(value, answer)
      )
    }
    return update(['form', 'questions', currentQuestion, 'answer'], value)
  }
  const curQuestionIdx = Object.values(questions).indexOf(questions[currentQuestion]);
  const nextQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx + 1]?.type;
  const previousQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx - 1]?.type;
  const progress = ((curQuestionIdx) / (Object.keys(questions).length - 1)) * 100;
  const resetForm = () => update(['form', 'submitSuccess'], false);
  const generateTableRows = (fields) => {
    const rows = fields.reduce((acc, val, idx) => {
      const headRow = ((idx % 2) === 0) ? `<tr style="background:rgba(0,0,0,0.3);">` : "<tr>";
      const value = val.subFields ? val.subFields?.reduce((acc, v) => {
        if (!questions[v.key]?.answer?.value) return acc;
        return acc.concat(`<p>${questions?.[v.key]?.reportLabel || v.label || questions?.[v.key]?.label}: ${questions[v.key]?.answer?.value}</p>`);
      }, '') : `${val.value}`;
      return acc.concat(
        headRow
          + `<th style="max-width: 300px;text-align:left;">`
            + `${val.label}`
          + "</th>"
          + "<td>"
            + value
          + "</td>"
        + "</tr>"
      );
    }, '');
    const html =
    `<h1 style="font-family:Arial;">C&R Asphalt Contact Form</h1>`
    + `<table style="font-family:Arial;border:2px solid rgb(237, 28, 36);">`
    + rows
    + `</table>`
    return html;
  }
  const fields = pipe(questions,
    q => keys(q),
    ks => reject(k => questions?.[k]?.noInput, ks),
    ks => reject(k => questions?.[k]?.inputPropType === 'file', ks),
    ks => reject(k => questions?.[k]?.answer === null, ks),
    ks => reject(k => questions?.[k]?.answer.length === 0, ks),
    ks => reject(k => questions?.[k]?.answer?.value === "", ks),
    // ks => reject(k => includes(k, hiddenFields), ks),
    ks => ks?.reduce((acc, k) => pipe(acc,
      a => assocPath([k], questions?.[k]?.answer?.value, a),
      a => {
        const _value = questions?.[k]?.answer?.value || questions?.[k]?.answer;
        console.log(_value);
        const value = typeof _value === 'object' ? _value.reduce((acc, val, idx) => {
          if (idx === 0) {
            return acc.concat(val.value);
          }
          return acc.concat(`, ${val.value}`)
        }, '') : _value.replace(/\n/g, "<br />");
        return assocPath([k, 'value'], value, a)
      },
      a => assocPath([k, 'subFields'], questions?.[k]?.subFields, a),
      a => assocPath(
        [k, 'label'],
        questions?.[k]?.reportLabel ||
        questions?.[k]?.label
          || questions?.[k]?.questionText
          || questions?.[k]?.questionTextHeader
          || questions?.[k]?.questionTextSubHeader, a),
    ), {}),

  );
  const emailPreview = generateTableRows(values(fields));
  React.useEffect(() => {
    update(['form', 'report'], emailPreview);
  }, [questions]);
  return(
  <ThemeProvider theme={theme}>
    <div className={classes.root}>
    {/*<div style={{maxWidth: 600, margin: 'auto'}}>
      <div dangerouslySetInnerHTML={{__html: emailPreview}} />
    </div>*/}
      {submitSuccess && <div>
          <Paper className={classes.success}>
            <div className={classes.successContent}>
            <Check fontSize='large' />
            <Typography>
              Success! Thank you for contacting us.
            </Typography>
            </div>
            <Button onClick={resetForm} fullWidth className={classes.submitAnotherFormBtn}>Click sere to submit another form.</Button>
          </Paper>
      </div>}
      {!submitSuccess && <div>
        <Typography variant='h6'>Get in touch with C & R by sending a quick message!</Typography>
          <div>
            <div>
              {keys(questions).map((q, i) => <div className={classes.questionReview} key={i}>
                <Typography color='textSecondary'>{questions[q]?.questionText === 'Type a question' && questions[q]?.questionText}</Typography>
                <Question
                  review={review}
                  currentQuestion={q}
                  questions={questions}
                  updateTextField={updateTextField}
                  updateButton={updateButton}
                  updatePhoneField={updatePhoneField}
                  answer={questions[q]?.answer} />
              </div>)}
            </div>
          </div>
          <div>
            {contact.form.submitFormError?.msg && <Paper className={classes.error}><Typography>{contact.form.submitFormError?.msg}</Typography></Paper>}

            <Button
                onClick={submitForm}
                disabled={contact.form.submittingForm || includes(null, values(map(q => q.answer, questions))) || includes('', values(map(q => q.answer?.value, questions)))}
                variant='contained'
                color='primary'>
                {contact.form.submittingForm ? 'Submitting Form...' : `Submit`}
          </Button>
          </div>
        </div>}
        <ThemeProvider theme={darkTheme}>
          <Paper square elevation={0} className={classes.banner}>
            <Typography align='center' variant='h2'>Serving Central Kentucky and Surrounding Areas</Typography>
            <Divider className={classes.divider} />
            <div className={classes.companyInfoContainer}>
              <div className={classes.companyInfoColumn}>
                {companyInfo.phone && <Typography>Tel: <a style={{color: darkTheme.palette.text.primary}} href={`tel:${companyInfo.phone}`}>{companyInfo.phone}</a></Typography>}
                {companyInfo.email && <Typography>Email: <a style={{color: darkTheme.palette.text.primary}} href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a></Typography>}
              </div>
              <div className={classes.companyInfoColumn}>
                {companyInfo.streetAddress && <Typography>{companyInfo.streetAddress}</Typography>}
                {companyInfo.state && companyInfo.city && <Typography>{`${companyInfo.city}, ${companyInfo.state}`}</Typography>}
              </div>
              <div className={classes.companyInfoColumn}>
                <Typography align={screenIsSmall ? 'left' : 'right'}>Week Days: 8am-5pm</Typography>
                <Typography align={screenIsSmall ? 'left' : 'right'}>Weekends: CLOSED</Typography>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12566.573539384934!2d-84.529735!3d38.055395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e1e13d274bf0658!2sC%20%26%20R%20Asphalt%2C%20LLC!5e0!3m2!1sen!2sus!4v1617216382661!5m2!1sen!2sus"
              width={screenIsSmall ? "350" : "600"}
              height={screenIsSmall ? "350" : "350"}
              style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
          </Paper>
        </ThemeProvider>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: {
  contact: typeof ContactState
}) => {
  const { contact } = state;
  return {
    contact
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      update,
      submitForm
    }, dispatch)
  };
};

const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default Form;
