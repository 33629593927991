import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroPage from '../components/services/hero';
import Contact from '../components/contact/contactForm';

const IndexPage = ({
  pageContext: {
    contactPage,
    companyInfo
  }
}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";
  return (
    <Layout>
      <SEO title={homePageSeoTitle} seo={contactPage.seo?.[0]} />
      <HeroPage
        title={contactPage.title}
        subtitle={contactPage.subtitle}
        noCta
        image={contactPage.mainImage} />
      <Contact companyInfo={companyInfo} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
